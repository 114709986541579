import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Modal, PageHeader, Row, Typography, Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BiPlus } from 'react-icons/bi';
import { TbBuildingFactory } from 'react-icons/tb';
import EditFileIcon from 'assets/svg/EditFileIcon';
import AddOrganizationModal from 'components/Modal/SuperAdmin/AddOrganization/AddOrganizationModal';
import TooltipTitle from 'components/core/TooltipTitle';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import HeaderLayout from 'layouts/HeaderLayout';
import { setSelectedOrganization } from 'store/organizations';
import { getOrganization, getOrganizations } from 'store/organizations/actions';
import { Organization } from 'store/organizations/types';
import { setSelectedProject } from 'store/projects';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

const Organizations = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingOrganizations, organizations } = useAppSelector((state) => state.organizations);
  const [isOpenCreateOrganizationModal, setCreateOrgnizationModal] = useState(false);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getOrganizations(token));
      dispatch(setSelectedOrganization(null));
      dispatch(setSelectedProject(null));
    });
  }, []);

  const handleOrganization = (data: Organization) => {
    dispatch(setSelectedOrganization(data));
    navigate(routes.ProjectList);
  };
  const handleAddOrganization = () => {
    navigate(routes.AddOrganization, { replace: true });
  };

  const handleMapview = () => {
    navigate(routes.MapPage, { replace: true });
  };

  const handleEditOrganization = (orgId: string) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getOrganization(orgId, token));
    });
    navigate(`${routes.EditOrganization}/${orgId}`, { replace: true });
  };

  return (
    <>
      <HeaderLayout />
      <div className="mainOrganizationContent">
        <PageHeader
          className="mainTitleContent"
          ghost={false}
          title="Organizations"
          extra={[
            <Button key="1" type="primary" className="topContentBtn" onClick={handleMapview}>
              Map View
            </Button>,
            <Button
              key="2"
              type="primary"
              className="topContentBtn"
              onClick={handleAddOrganization}
            >
              Add organization
            </Button>
          ]}
        />
      </div>
      <Spin
        size="large"
        tip="Loading..."
        spinning={isLoadingOrganizations}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <div className="boxesRowMain">
          <Row className="boxesRow">
            {organizations?.map((data: Organization) => {
              let projectClass;
              let notificationClass;
              if (data.projects > 0) {
                projectClass = 'existProject';
              }
              if (data.notifications > 0) {
                notificationClass = 'getNotification';
              }
              return (
                <Col span={6} className="mainBox" key={data.id}>
                  <div className="boxes" onClick={() => handleOrganization(data)}>
                    <Tooltip
                      color="#fff"
                      title={<TooltipTitle className="org-title" title={data.name} />}
                    >
                      <Text>{data.name}</Text>
                    </Tooltip>
                    <div className="imgOfLogo">
                      {data.logo_url ? (
                        <img src={data.logo_url} alt="sterlitePower" />
                      ) : (
                        <TbBuildingFactory size={65} />
                      )}
                    </div>
                    <div className="projectsAndNotification">
                      <Text className={`projects ${projectClass}`}>
                        Projects<Text>{data.projects}</Text>
                      </Text>
                      <Text className="line">|</Text>
                      <Text className={`notification ${notificationClass}`}>
                        Notifications<Text> - {data.notifications ? data.notifications : 0}</Text>
                      </Text>
                    </div>
                  </div>
                  <div className="edit-org" onClick={() => handleEditOrganization(data.id)}>
                    <EditFileIcon />
                  </div>
                </Col>
              );
            })}
            <Col span={6} className="mainBox" onClick={handleAddOrganization}>
              <div className="staticBox">
                <div className="pluseIcon">
                  <BiPlus />
                </div>
                <Text className="staticText">Add new organization</Text>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
      <Modal
        open={isOpenCreateOrganizationModal}
        okText="Create"
        cancelText="Cancel"
        centered
        width={700}
        closable={false}
        onCancel={() => setCreateOrgnizationModal(false)}
        footer={null}
      >
        <AddOrganizationModal setCreateOrgnizationModal={setCreateOrgnizationModal} />
      </Modal>
    </>
  );
};

export default Organizations;
