import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { GOOGLE_MAP_API_KEY } from 'constants/common.constant';
import { Maptype } from 'types/common.types';

interface GoogleMapLayerProps {
  maptype: Maptype;
}

const GoogleMapLayer = ({ maptype }: GoogleMapLayerProps) => {
  // @ts-ignore
  return <ReactLeafletGoogleLayer apiKey={GOOGLE_MAP_API_KEY} type={maptype} />;
};

export default GoogleMapLayer;
